<template>
  <div class="orderDetails" v-loading="detailsLoad" v-if="detailsShow">
    <div class="orderContent">
      <div class="orderBox">
        <h2>订单信息</h2>
        <div class="orderInfo">
          <p>订单：<span>{{detailsData.orderNo}}</span></p>
          <p>订单状态：<span :class="'orderStatus orderStatus'+detailsData.status">{{detailsData.status|orderStatus}}</span></p>
          <p>支付方式：<span>{{detailsData.payWay|payWay}}</span></p>
          <p>订单类型：<span>{{detailsData.type|orderType}}</span></p>
          <p>订单创建时间：<span>{{detailsData.createTime}}</span></p>
          <p>支付时间：<span>{{detailsData.payTime || '- -'}}</span></p>
          <p>订单金额：<span v-if="detailsData.price">￥{{detailsData.price}}</span><span v-else>- -</span></p>
        </div>
        <div class="orderDetailsClose">
          <el-button type="default" plain size="small" @click="closeDetails">返回列表</el-button>
          <el-button type="default" plain size="small" v-if="detailsData.status<3" @click="cancelCloudCert">取消</el-button>
          <el-button type="primary" size="small" v-if="detailsData.status===2" @click="goPayment(detailsData.orderNo)">付款</el-button>
          <el-button type="primary" size="small" @click="$router.push({ name: 'integration' })" v-if="detailsData.status===4">接口文档</el-button>
        </div>
      </div>
      <div class="orderBox">
        <h2>产品信息</h2>
        <div class="productHead">
          <span>产品名称</span>
          <span>产品类型</span>
          <span>规格</span>
          <span>价格</span>
        </div>
        <div class="productDetails">
          <p>{{detailsData.productType|productType}}</p>
          <p>{{detailsData.productName}}</p>
          <p>规格：{{detailsData.productSpecs|productSpecs}}<br><i v-if="detailsData.times">周期：{{detailsData.times}}次</i></p>
          <p>{{detailsData.price||'- -'}}</p>
        </div>
      </div>
      <div class="orderBox">
        <h2>集成企业</h2>
        <div class="productHead">
          <span>企业名称</span>
          <span>API认证账号（AppId）</span>
          <span>统一社会信用代码</span>
          <span>法人姓名</span>
        </div>
        <div class="productDetails">
          <p>{{detailsData.customerName}}</p>
          <p>{{detailsData.appId}}</p>
          <p>{{detailsData.identNo}}</p>
          <p>{{detailsData.legalPersonName}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderStatus, orderType, productSpecs, productType, payWay } from '@/utils/typeMatch';
export default {
  data () {
    return {
      detailsLoad: false,
      detailsShow: false,
      orderNo: '',
      detailsData: {},
      products: [
        {
          name: '网络身份认证服务',
          type: '身份证实名认证',
          rules: '规格：纯API版\r\n周期：1000次',
          price: '100',
        }
      ],
      purchasingEntity: [
        {
          appId: 'up_1jbyumkq809k_6n0h',
          name: '山东豸信认证服务有限公司',
          creditCode: 'EURUBNSHDBSHB2928382',
          legalPersonName: '梅臻'
        }
      ]
    }
  },
  filters: {
    orderStatus, orderType, productSpecs, productType, payWay
  },
  methods: {
    getOrderDetails () {
      this.detailsShow = true
      this.detailsLoad = true
      this.$api.getNetworkDetail({ orderNo: this.orderNo }).then(res => {
        this.detailsLoad = false
        this.detailsData = res.data
      }).catch(() => {
        this.detailsLoad = false
      })
    },
    closeDetails () {
      this.detailsShow = false
    },
    goPayment (orderNo) {
      this.$store.commit('user/setOrderNo', orderNo)
      this.$router.push({ name: 'identityAuth' })
    },
    cancelCloudCert () {
      this.$confirm(`确定取消申请？`, '提示', {
        confirmButtonText: '确定',
        showCancelButton: '取消',
        type: 'warning'
      }).then(() => {
        this.cancelOrder()
      }).catch(() => { })
    },
    cancelOrder () {
      this.detailsLoad = true
      this.$api.cancelOrder({ orderNo: this.orderNo }).then(res => {
        this.detailsLoad = false
        if (res.code === '0') {
          this.$message.success(res.msg)
          this.getOrderDetails()
          this.$parent.refreshOrder()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.detailsLoad = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.orderDetails {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 9;
  background-color: #f5f8fb;
}
.orderContent {
  width: 1300px;
  min-height: 100%;
  margin: 24px auto 0;
  padding: 0 40px 24px;
  background-color: #fff;
  position: relative;
}
.orderBox {
  padding: 40px 0 0;
  &:first-child {
    padding: 32px 0 0;
  }
  h2 {
    font-size: 14px;
    color: #303b50;
    margin-bottom: 24px;
    line-height: 19px;
    padding-left: 10px;
    position: relative;
    font-weight: 700;
    &::before {
      content: '';
      width: 2px;
      height: 14px;
      background: #1c67f3;
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
  ::v-deep .el-table .cell {
    color: #303b50;
  }
  ::v-deep .el-table th {
    padding: 9px 0;
    background: #f2f3f9;
    .cell {
      color: #6d7882;
    }
  }
  ::v-deep .el-table td {
    padding: 14px 0;
  }
  ::v-deep .el-table .cell {
    font-weight: normal;
  }
}
.productHead {
  width: 100%;
  height: 42px;
  background: #f2f3f9;
  padding-left: 24px;
  span {
    display: inline-block;
    width: 280px;
    font-size: 14px;
    color: #6d7882;
    line-height: 42px;
  }
}
.productDetails {
  padding: 16px 24px;
  border-bottom: 1px solid #dee0e9;
  p {
    display: inline-block;
    width: 280px;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    vertical-align: text-top;
    padding-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    display: inline-block;
    margin-top: 14px;
  }
}
.orderInfo {
  p {
    width: 33.3%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 24px;
    font-size: 14px;
    color: #6c737b;
    line-height: 19px;
    cursor: default;
    &:last-child {
      padding-bottom: 0;
    }
  }
  span {
    color: #303b50;
  }
  .orderStatus {
    display: inline-block;
    height: 24px;
    padding: 0 8px;
    font-weight: normal;
    line-height: 22px;
    border-radius: 15px;
    &.orderStatus1,
    &.orderStatus2 {
      color: #e89e1c;
      background: #fef8ec;
      border: 1px solid #fbbc4d;
    }
    &.orderStatus3,
    &.orderStatus4 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
    &.orderStatus5 {
      color: #e34d59;
      background: #ffeeee;
      border: 1px solid #f7caca;
    }
    &.orderStatus6 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
  }
}
.orderDetailsClose {
  position: absolute;
  right: 24px;
  top: 32px;
  .el-button.el-button--small {
    line-height: 16px;
  }
}
</style>