<template>
  <div v-if="showInfo" class="transactionDetail">
    <el-scrollbar v-if="detailData" class="transactionDetailMain">
      <div class="transactionHead">
        <div class="objectBox">
          所属项目：<span>{{ detailData.transactionEntity.projectName }}</span>
        </div>
        <p class="objectTip">
          <span class=" el-icon-warning"></span>
          温馨提示：当前内容为业务单详细信息
        </p>
      </div>
      <!-- ---------------------- -->
      <div class="transactionBody">
        <div class="detailsInfo">
          <h3 class="InfoTitle">业务单信息</h3>
          <div class="btnMain">
            <p>
              业务单名称：<span>{{ detailData.transactionEntity.name }}</span>
            </p>
            <p>
              业务类型：<span>{{
                detailData.transactionEntity.type | transactionTypeFilter
              }}</span>
            </p>
            <p>
              业务单号：<span>{{
                detailData.transactionEntity.transactionNo
              }}</span>
            </p>
            <p>
              提交时间：<span>{{
                detailData.transactionEntity.createdDate
              }}</span>
            </p>
            <p v-if="detailData.transactionEntity">
              业务单状态：<i
                :class="
                  'statusColor statusColor' +
                    detailData.transactionEntity.status
                "
                >{{
                  detailData.transactionEntity.status | certTransStatusFilter
                }}</i
              >
            </p>
            <div class="btnBox">
              <el-button @click="returnList" size="small">返回列表</el-button>
              <el-button
                v-if="[1, 2, , 4].includes(detailData.transactionEntity.status)"
                @click="
                  transactionCancel(
                    detailData.transactionEntity &&
                      detailData.transactionEntity.id
                  )
                "
                size="small"
                >取消申请
              </el-button>
              <el-button
                v-if="detailData.ukeyLogisticsEntity !== undefined"
                @click="getmailNew(detailData.ukeyLogisticsEntity.busiNo)"
                size="small"
                >查看物流</el-button
              >
              <el-button
                v-if="
                  detailData.transactionEntity.status < 11 ||
                    detailData.transactionEntity.status === 14
                "
                @click="
                  getOpenPage({
                    name: businessType[detailData.transactionEntity.type],
                    transId: detailData.transactionEntity.id
                  })
                "
                type="primary"
                size="small"
                >继续办理
              </el-button>
              <el-button
                v-if="
                  (detailData.transactionEntity.type === 2 ||
                    detailData.transactionEntity.type === 3) &&
                    detailData.transactionEntity.status === 11
                "
                @click="rewriteUkey"
                type="primary"
                size="small"
                >重新写入
              </el-button>
            </div>
          </div>
        </div>
        <!-- ---------------------- -->
        <template v-if="[1, 2, 9].includes(detailData.transactionEntity.type)">
          <div
            class="detailsInfo"
            v-if="detailData.applyFormEntity.certType !== 1"
          >
            <h3 class="InfoTitle">证书所属机构信息</h3>
            <p>
              机构名称/O：<span>{{
                detailData.applyFormEntity.orgInfoEntity.organization
              }}</span>
            </p>
            <p>
              机社会统一信用代码：<span>{{
                detailData.applyFormEntity.orgInfoEntity.orgIdentNo
              }}</span>
            </p>
            <p>
              部门/OU：<span>{{
                detailData.applyFormEntity.orgInfoEntity.organizationalUnit
              }}</span>
            </p>
            <p>
              企业注册地址：<span>{{
                detailData.applyFormEntity.orgInfoEntity.stateOrProvince
                  ? detailData.applyFormEntity.orgInfoEntity.stateOrProvince +
                    '-' +
                    detailData.applyFormEntity.orgInfoEntity.locality
                  : '- -'
              }}</span>
            </p>
            <p>
              法定代表人姓名：<span>{{
                detailData.applyFormEntity.orgInfoEntity.legalPersonName ||
                  '- -'
              }}</span>
            </p>
            <p>
              法人证件类型：<span
                v-if="
                  detailData.applyFormEntity.orgInfoEntity.legalPersonIdentNo
                "
                >{{
                  detailData.applyFormEntity.orgInfoEntity.legalPersonIdentType
                    | identTypeFilter
                }}</span
              ><span v-else>- -</span>
            </p>
            <p>
              法人证件号码：<span>{{
                detailData.applyFormEntity.orgInfoEntity.legalPersonIdentNo ||
                  '- -'
              }}</span>
            </p>
          </div>
          <div class="detailsInfo">
            <h3 class="InfoTitle">证书详细信息</h3>
            <p v-if="[9].includes(detailData.transactionEntity.type)">
              证书序列号：<span>{{ detailData.applyFormEntity.certSn }}</span>
            </p>
            <p>
              证书类型：<span>{{
                detailData.transactionEntity.certType | certTypeFilter
              }}</span>
            </p>
            <p v-if="[1].includes(detailData.transactionEntity.type)">
              证书有效期：<span
                >{{ detailData.transactionEntity.certDuration / 365 }}年</span
              >
            </p>
            <p v-else>
              证书有效期：<span>{{
                `${detailData.certStartDate &&
                  detailData.certStartDate.split(
                    ' '
                  )[0]}至${detailData.certEndDate &&
                  detailData.certEndDate.split(' ')[0]}`
              }}</span>
            </p>
            <p v-if="!detailData.applyFormEntity.personInfoEntity">
              证书名称/CN：<span
                v-if="detailData.applyFormEntity.certCommonName"
                >{{ detailData.applyFormEntity.certCommonName }}</span
              ><span v-else>{{
                detailData.applyFormEntity.orgInfoEntity.organization
              }}</span>
            </p>
            <div
              class="infoPBox"
              v-if="detailData.applyFormEntity.personInfoEntity"
            >
              <p>
                证书名称/CN：<span
                  v-if="detailData.applyFormEntity.certCommonName"
                  >{{ detailData.applyFormEntity.certCommonName }}</span
                ><span v-else>{{
                  detailData.applyFormEntity.personInfoEntity.name
                }}</span>
              </p>
              <p>
                证件类型：<span>{{
                  detailData.applyFormEntity.personInfoEntity.identType
                    | identTypeFilter
                }}</span>
              </p>
              <p>
                证件号码：<span>{{
                  detailData.applyFormEntity.personInfoEntity.identNo
                }}</span>
              </p>
              <p>
                手机号：<span>{{
                  detailData.applyFormEntity.personInfoEntity.mobile
                }}</span>
              </p>
              <p>
                邮箱：<span>{{
                  detailData.applyFormEntity.personInfoEntity.email || '- -'
                }}</span>
              </p>
            </div>
            <p>
              经办人姓名：<span>{{
                detailData.applyFormEntity.orgInfoEntity.agentName
              }}</span>
            </p>
            <p>
              经办人身份证号：<span>{{
                detailData.applyFormEntity.orgInfoEntity.agentIdentNo
              }}</span>
            </p>
            <p>
              经办手机号：<span>{{
                detailData.applyFormEntity.orgInfoEntity.agentMobile
              }}</span>
            </p>
          </div>
        </template>
        <div class="detailsInfo" v-else>
          <h3 class="InfoTitle">证书信息</h3>
          <p>
            证书序列号：<span>{{ detailData.applyFormEntity.certSn }}</span>
          </p>
          <p v-if="detailData.applyFormEntity.certType !== 1">
            机构名称/O：<span>{{
              detailData.applyFormEntity.orgInfoEntity.organization
            }}</span>
          </p>
          <p v-if="detailData.applyFormEntity.certType !== 1">
            部门/OU：<span>{{
              detailData.applyFormEntity.orgInfoEntity.organizationalUnit
            }}</span>
          </p>
          <p v-if="detailData.applyFormEntity.certType === 1">
            证书名称/CN：<span
              v-if="detailData.applyFormEntity.certCommonName"
              >{{ detailData.applyFormEntity.certCommonName }}</span
            ><span v-else>{{
              detailData.applyFormEntity.personInfoEntity.name
            }}</span>
          </p>
          <p v-if="!detailData.isEqualToCurrentLoginUser">
            经办人姓名：<span>{{
              detailData.applyFormEntity.personInfoEntity.agentName
            }}</span>
          </p>
          <p v-if="!detailData.isEqualToCurrentLoginUser">
            经办人身份证号：<span>{{
              detailData.applyFormEntity.personInfoEntity.agentIdentNo
            }}</span>
          </p>
          <p v-if="!detailData.isEqualToCurrentLoginUser">
            经办手机号：<span>{{
              detailData.applyFormEntity.personInfoEntity.agentMobile
            }}</span>
          </p>
          <div
            class="detailsInfo detailsSpecial"
            v-if="detailData.applyFormEntity.orgInfoEntity"
          >
            <p>
              证书名称/CN：<span
                v-if="detailData.applyFormEntity.certCommonName"
                >{{ detailData.applyFormEntity.certCommonName }}</span
              ><span v-else>{{
                detailData.applyFormEntity.orgInfoEntity.organization
              }}</span>
            </p>
            <p>
              证件类型：<span>{{
                detailData.applyFormEntity.orgInfoEntity.orgIdentType
                  | identTypeFilter
              }}</span>
            </p>
            <p>
              证件号码：<span>{{
                detailData.applyFormEntity.orgInfoEntity.orgIdentNo
              }}</span>
            </p>
            <p>
              经办人姓名：<span>{{
                detailData.applyFormEntity.orgInfoEntity.agentName
              }}</span>
            </p>
            <p>
              经办人身份证号：<span>{{
                detailData.applyFormEntity.orgInfoEntity.agentIdentNo
              }}</span>
            </p>
            <p>
              经办手机号：<span>{{
                detailData.applyFormEntity.orgInfoEntity.agentMobile
              }}</span>
            </p>
          </div>
        </div>
        <div
          class="detailsInfo"
          v-if="
            detailData.applyFormEntity.sealInfoEntitys &&
              detailData.applyFormEntity.isSealForm
          "
        >
          <h3 class="InfoTitle">印迹信息</h3>
          <p class="line sealImg">
            <img
              :src="
                detailData.applyFormEntity.sealInfoEntitys[0].patternFilePath
              "
            />
          </p>
          <p>
            印迹尺寸：<span
              >{{
                detailData.applyFormEntity.sealInfoEntitys[0].patternWidth
              }}mm x
              {{
                detailData.applyFormEntity.sealInfoEntitys[0].patternHeight
              }}mm</span
            >
          </p>
        </div>
        <!-- ---------------------- -->
        <div class="detailsInfo" v-if="detailData.transactionEntity.type == 3">
          <h3 class="InfoTitle">续期信息</h3>
          <p>
            续期年限：<span
              >{{ detailData.applyFormEntity.certDuration / 365 }}年</span
            >
          </p>
        </div>
        <!-- ---------------------- -->
        <template v-if="[1, 9].includes(detailData.transactionEntity.type)">
          <div class="detailsInfo" v-if="detailData.applyFormEntity.shipping">
            <h3 class="InfoTitle">邮寄信息</h3>
            <p>
              收件人：<span>{{
                detailData.applyFormEntity.recipientName || '- -'
              }}</span>
            </p>
            <p>
              收件人手机号：<span>{{
                detailData.applyFormEntity.recipientMobile || '- -'
              }}</span>
            </p>
            <p>
              收件地址：<span>{{
                detailData.applyFormEntity.mailingAddress
              }}</span>
            </p>
          </div>
          <div class="detailsInfo" v-else>
            <h3 class="InfoTitle">自取信息</h3>
            <p>
              地址：<span
                >山东省济南市历城区唐冶绿地城市大脑数字产业小镇AD23号楼101（商墅）</span
              >
            </p>
            <p>咨询电话：<span>400-030-2013</span></p>
          </div>
        </template>

        <div
          class="detailsInfo"
          v-show="detailData.applyFormEntity.applyFileName"
        >
          <h3 class="InfoTitle">上传资料信息</h3>
          <div class="fileBox">
            <div class="fileTitle">
              <img
                class="icon"
                src="@/assets/icons/service/pdf-icon.png"
                alt=""
                srcset=""
              />
              <div class="name" @click="getpdf">
                {{ detailData.applyFormEntity.applyFileName }}
              </div>
            </div>
          </div>
        </div>
        <div class="detailsInfo">
          <h3 class="InfoTitle">审核信息</h3>
          <p>
            审核结果：<span>{{ getauditresult }}</span>
          </p>
          <p>
            审核时间：<span>{{ detailData.auditTime || '- -' }}</span>
          </p>
          <p class="line">
            审核备注：<span>{{ detailData.auditReason || '- -' }}</span>
          </p>
        </div>
        <div
          class="detailsInfo"
          v-if="[1, 2, 3, 9].includes(detailData.transactionEntity.type)"
        >
          <h3 class="InfoTitle">付款信息</h3>
          <p>
            付款金额：<span v-if="detailData.transactionEntity.status > 3">{{
              detailData.transactionEntity.discountTotal
                ? detailData.transactionEntity.discountTotal + '元'
                : '- -'
            }}</span>
            <span v-else-if="detailData.transactionEntity.status <= 3"
              >- -</span
            >
          </p>
          <p>
            付款时间：<span>{{
              (detailData.paymentEntity && detailData.paymentEntity.payTime) ||
                '- -'
            }}</span>
          </p>
        </div>
        <el-dialog
          :append-to-body="true"
          :show-close="false"
          class="dialog-pdf"
          :visible.sync="dialog_pdf"
          top="2%"
          width="800px"
        >
          <PdfPreview class="pdf-box" :url="pdfPreviewUrl"></PdfPreview>
        </el-dialog>
      </div>
    </el-scrollbar>

    <v-mailNews ref="mailNews"></v-mailNews>
  </div>
</template>
<script>
import mailNews from '@/components/mailNews'
import {
  certTransStatus,
  transactionType,
  CertType,
  IdentType
} from '@/utils/typeMatch.js'
export default {
  data() {
    return {
      detailData: '',
      showInfo: false,
      dialog_pdf: false,
      pdfPreviewUrl: '',
      detailId: '',
      businessType: {
        1: 'certApply',
        2: 'certChange',
        3: 'certRenewal',
        4: 'certCancel',
        7: 'certUnlock',
        9: 'certReissue'
      },
      certData: ''
    }
  },
  filters: {
    certTransStatusFilter: certTransStatus,
    transactionTypeFilter: transactionType,
    certTypeFilter: CertType,
    identTypeFilter: IdentType
  },
  components: {
    'v-mailNews': mailNews
  },
  computed: {
    getauditresult() {
      let result = ''
      switch (
        this.detailData.transactionEntity &&
        this.detailData.transactionEntity.status
      ) {
        case 3:
          result = '未通过'
          break
        case 1:
          result = '未审核'
          break
        case 2:
          result = '审核中'
          break
        case 12:
          result =
            this.detailData && this.detailData.auditTime ? '通过' : '未审核'
          break
        default:
          result = '通过'
      }
      return result
    }
  },
  mounted() {},
  methods: {
    getOpenPage(item) {
      let newPage = this.$router.resolve({
        name: item.name,
        query: { transId: item.transId }
      })
      window.open(newPage.href, '_blank')
    },
    getDetail(item) {
      if (!item) return false
      this.detailId = item
      this.showInfo = true
      this.$api
        .getTransactionDetail({
          transactionId: item
        })
        .then((data) => {
          if (data.code === '0') {
            this.detailData = data.data
          } else {
            this.showInfo = false
            this.$message.error(data.msg)
          }
        })
    },
    getpdf() {
      this.$api
        .getPrivateBucketUrl({
          filePath: this.detailData.applyFormEntity.applyFilePath
        })
        .then((data) => {
          this.dialog_pdf = true
          this.pdfPreviewUrl = data.data
        })
    },
    transactionCancel(transId) {
      this.$confirm('确认取消该业务单吗？', '取消确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.transactionCancel({ transId }).then(() => {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getDetail(this.detailId)
          this.$emit('getlistData')
        })
      })
    },
    rewriteUkey() {
      this.$parent.Ukey.connect()
        .then(() => {
          this.$parent.ukeyLogined = () => {
            this.$parent.renewaling = true
            this.getRemakeCert()
          }
          this.$parent.ukeyLoginShow = true
        })
        .catch((err) => {
          if (err.message) this.$message.error(err.message)
        })
    },
    getRemakeCert() {
      this.renewaling = true
      this.$api
        .getRemakeCert({
          transId: this.detailData.transactionEntity.id,
          certSn: this.$parent.Ukey.certSn
        })
        .then((res) => {
          this.certData = res.data
          this.$parent.Ukey.writeCert(this.certData)
            .then(() => {
              this.$parent.renewaling = false
              if (res) {
                this.$message({
                  type: 'success',
                  message: '写入成功！',
                  onClose: () => {}
                })
              } else {
                this.$message.error(res.message)
              }
            })
            .catch((err) => {
              this.$parent.renewaling = false
              this.$message.error(err.message)
            })
        })
        .catch(() => {
          this.$parent.renewaling = false
        })
    },
    returnList() {
      // if (this.$route.fullPath === '/personCenter/verified') {
      //   this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
      // } else {
      this.showInfo = false
      // }
    },
    getmailNew(transId) {
      this.$api
        .getlogisticsByTransId({
          transId: transId
        })
        .then((data) => {
          if (data.data && data.data.expressNo) {
            this.$refs.mailNews.getmailNewData(data.data.expressNo)
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.transactionDetail {
  position: fixed;
  top: 59px;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 100;
  padding: 0 160px;
  background-color: #f1f3f7;

  .buttonRouter {
    color: #fff;
  }

  .objectBox {
    font-size: 14px;
    color: #2a2b2c;
    padding: 16px 0;

    span {
      color: $primaryColor;
    }
  }

  .objectTip {
    height: 48px;
    line-height: 48px;
    background-color: #fff;
    font-size: 14px;
    color: #333333;
    padding-left: 24px;
    margin-bottom: 16px;

    span {
      font-size: 16px;
      color: $primaryColor;
      margin-right: 4px;
    }
  }

  .transactionDetailMain {
    height: 100%;

    // overflow-y: scroll;
    ::v-deep .el-scrollbar__wrap {
      padding: 0;
    }
  }

  .transactionBody {
    background-color: #fff;
    padding: 0 24px 24px;
  }

  .detailsInfo {
    padding: 16px 0px 0px;

    &:first-child {
      padding: 27px 0px 0px;
    }

    .InfoTitle {
      font-size: 14px;
      color: #505256;
      margin-bottom: 24px;
      line-height: 14px;
      padding: 0 0 16px 8px;
      position: relative;
      font-weight: 700;
      border-bottom: 1px solid #dee2e8;

      &::before {
        content: '';
        width: 3px;
        height: 16px;
        background: $primaryColor;
        position: absolute;
        bottom: 16px;
        left: 0;
      }
    }

    > p,
    .btnMain > p,
    .infoPBox > p {
      width: 50%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 8px;
      padding-bottom: 20px;
      font-size: 14px;
      color: #6c737b;
      line-height: 19px;
      cursor: default;

      &.blockP {
        width: 100%;
        padding: 0 8px;
      }

      &.line {
        width: 100%;
      }

      &.application {
        width: 100%;

        > .pdf_div {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 40px);
          height: 40px;
          margin-top: 10px;
          padding: 0 20px 0 55px;
          line-height: 40px;
          // background: #fbfbfb url('./../../../assets/img/PDF_1.png') no-repeat 20px center;
          background-size: 22px 26px;
          border-radius: 2px;
          // color: $content--theme-color;
        }
      }
    }

    span {
      font-weight: 600;
      color: #303b50;
    }

    .btnMain {
      position: relative;

      // display: flex;
      // justify-content: space-between;
      .btnBox {
        position: absolute;
        right: 0;
        top: 0;

        ::v-deep .el-button {
          padding: 9px 16px;
        }
      }
    }
  }

  .detailsSpecial {
    padding-top: 0;
  }

  .fileBox {
    height: 68px;
    width: 1100px;
    border-radius: 2px;
    display: flex;
    margin-bottom: 24px;
    padding-left: 56px;
    background: #f7faff url('~@/assets/icons/service/filesUpload.png') no-repeat
      left center;
    background-size: 24px 120px;

    .fileTitle {
      display: flex;
      align-items: center;
      width: 100%;

      .icon {
        width: 24px;
        height: 25px;
        margin-right: 12px;
      }

      .name {
        width: 100%;
        color: #303b50;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          color: $primaryColor;
        }
      }
    }
  }

  .sealImg {
    width: 100% !important;

    img {
      max-height: 120px;
      max-width: 120px;
      width: 100%;
    }
  }
}
</style>
