<template>
  <div class="signatureDetails" v-if="detailsDisplay" v-loading="detailsLoad">
    <div class="signatureContent">
      <div class="signatureBox">
        <h2>业务单信息</h2>
        <div class="signatureInfo">
          <p class="wholeLine">业务单名称：<span>{{detailsData.transactionName}}</span></p>
          <p>业务单号：<span>{{detailsData.transactionNo}}</span></p>
          <p>业务类型：<span>{{detailsData.transactionType===0?'电子版':'纸质版'}}</span></p>
          <p>业务单状态：<span :class="'statusColor statusColor'+detailsData.transactionStatus">{{detailsData.transactionStatus|reportStatus}}</span></p>
          <p>付款金额：<span>¥ {{detailsData.amount}}</span></p>
          <p>创建时间：<span>{{detailsData.createDate}}</span></p>
        </div>
        <div class="signatureDetailsClose">
          <el-button type="default" plain size="small" @click="closeDetails">返回列表</el-button>
          <el-button type="default" plain size="small" v-if="detailsData.transactionStatus===1" @click="cancelReport(detailsData.transactionType)">取消</el-button>
          <el-button type="primary" size="small" v-if="detailsData.transactionStatus===1" @click="goPayment">付款</el-button>
          <el-button type="primary" size="small" v-if="detailsData.transactionStatus===6" @click="getmailNew(detailsData.expressNo)">查看物流</el-button>
        </div>
      </div>
      <div class="signatureBox" v-if="detailsData.transactionType===1">
        <h2>领取方式</h2>
        <div class="signatureInfo">
          <p class="wholeLine">领取方式：<span>{{detailsData.receiveType===1?'邮寄到付':'自取'}}</span></p>
          <div v-if="detailsData.receiveType===1">
            <p>收件人：<span>{{detailsData.receiver}}</span></p>
            <p>联系电话：<span>{{detailsData.phone}}</span></p>
            <p class="wholeLine">收件地址：<span>{{detailsData.deliveryAddress}}</span></p>
          </div>
          <div v-else>
            <p class="wholeLine">自取地址：<span>山东省济南市历城区唐冶绿地城市大脑数字产业小镇AD23号楼101（商墅）</span></p>
            <p>咨询电话：<span>400-030-2013</span></p>
          </div>
        </div>
      </div>
      <div class="signatureBox">
        <h2>附件</h2>
        <div class="signatureInfo">
          <p>出证原文件：</p>
          <div class="file">
            <img src="@/assets/icons/service/filesUpload.png" class="left-icon">
            <div class="title">
              <img class="icon" src="@/assets/icons/service/pdf-icon.png">
              <el-button type="text" class="name" @click="openPdf">{{detailsData.originalPdfName}}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :append-to-body="true" :show-close="false" class="dialog-pdf" :visible.sync="dialog_pdf" top="2%" width="800px">
      <PdfPreview class="pdf-box" :url="detailsData.originalPdfUrl"></PdfPreview>
    </el-dialog>
    <v-mailNews ref="mailNews"></v-mailNews>
  </div>
</template>

<script>
import mailNews from '@/components/mailNews'
import { reportStatus } from '@/utils/typeMatch';
export default {
  data () {
    return {
      detailsLoad: false,
      detailsDisplay: false,
      dialog_pdf: false,
      transactionId: '',
      detailsData: {}
    }
  },
  filters: {
    reportStatus
  },
  components: {
    'v-mailNews': mailNews,
  },
  methods: {
    closeDetails () {
      this.detailsDisplay = false
    },
    getReportDetails () {
      this.detailsDisplay = true
      this.detailsLoad = true
      this.$api.getReportDetail({ transactionId: this.transactionId }).then(res => {
        this.detailsLoad = false
        this.detailsData = res.data
      }).catch(() => {
        this.detailsLoad = false
      })
    },
    openPdf () {
      this.dialog_pdf = true
    },
    cancelReport (transactionType) {
      this.$confirm('确认取消该业务单吗？', '取消确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.detailsLoad = true
        this.$api.reportCancel({ transactionId: this.transactionId }).then((res) => {
          this.detailsLoad = false
          if (res.code === '0') {
            this.$message.success('取消成功')
            this.getReportDetails()
            this.$parent.refreshData(transactionType)
          } else {
            this.$message.error(res.msg)
          }
        }).catch(() => {
          this.detailsLoad = false
        })
      })
    },
    goPayment () {
      this.$router.push({ name: 'verifyReport', query: { transactionId: this.transactionId }, })
    },
    getmailNew (expressNo) {
      this.$refs.mailNews.getmailNewData(expressNo)
    },
  }
}
</script>

<style lang="scss" scoped>
.signatureDetails {
  position: fixed;
  top: 59px;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 9;
  background-color: #f5f8fb;
}
.signatureContent {
  width: 1300px;
  min-height: 100%;
  margin: 24px auto 0;
  padding: 0 32px 24px;
  background-color: #fff;
  position: relative;
}
.signatureBox {
  padding: 18px 0 0;
  &:first-child {
    padding: 40px 0 0;
  }
  h2 {
    font-size: 14px;
    color: #303b50;
    margin-bottom: 24px;
    line-height: 19px;
    padding-left: 10px;
    position: relative;
    font-weight: 700;
    &::before {
      content: '';
      width: 2px;
      height: 14px;
      background: #1c67f3;
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
  ::v-deep .el-table .cell {
    color: #303b50;
  }
  ::v-deep .el-table th {
    padding: 9px 0;
    background: #f2f3f9;
    .cell {
      color: #6d7882;
    }
  }
  ::v-deep .el-table td {
    padding: 14px 0;
  }
  ::v-deep .el-table .cell {
    font-weight: normal;
  }
}
.signatureInfo {
  p {
    width: 33.3%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 16px;
    font-size: 14px;
    color: #6c737b;
    line-height: 19px;
    cursor: default;
  }
  .wholeLine {
    width: 100%;
  }
  span {
    color: #303b50;
  }
  .statusColor {
    display: inline-block;
    height: 24px;
    padding: 0 8px;
    font-weight: normal;
    line-height: 22px;
    border-radius: 15px;
    &.statusColor1,
    &.statusColor2,
    &.statusColor5 {
      color: #e89e1c;
      background: #fef8ec;
      border: 1px solid #fbbc4d;
    }
    &.statusColor3,
    &.statusColor6 {
      color: #1c67f3;
      background: #dbe4ff;
      border: 1px solid #a9beff;
    }
    &.statusColor-1 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
    &.statusColor7 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
  }
}
.signatureDetailsClose {
  position: absolute;
  right: 24px;
  top: 32px;
  .el-button.el-button--small {
    line-height: 16px;
  }
}
.file {
  width: 948px;
  height: 72px;
  background: #f7faff;
  border-radius: 2px;
  border: 1px dashed #b9c2f2;
  .left-icon {
    width: 24px;
    height: 70px;
    float: left;
  }
  .title {
    height: 70px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon {
    width: 24px;
  }
  .name {
    flex: auto;
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-left: 12px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: $primaryColor;
    }
  }
}
</style>