<template>
  <div class="cloudCertDetails" v-if="detailsDisplay" v-loading="detailsLoad">
    <div class="cloudCertContent">
      <div class="cloudCertClose">
        <el-button type="default" plain size="small" @click="closeDetails">返回列表</el-button>
        <el-button type="default" plain size="small" v-if="cloudCertDetail.status<3" @click="cancelCloudCert">取消</el-button>
        <el-button type="primary" size="small" @click="goPayment(cloudCertDetail.orderNo,cloudCertDetail.productSpecs)" v-if="cloudCertDetail.status===2">付款</el-button>
        <el-button type="primary" size="small" @click="$router.push({ name: 'integration' })" v-if="cloudCertDetail.status===4">接口文档</el-button>
      </div>
      <div class="cloudCertIllustrate">
        <h2>业务申请</h2>
        <div class="applyInfo clearfix">
          <p><span>认证账号（AppId）：</span>{{cloudCertDetail.appId}}</p>
          <p><span>申请主体：</span>{{cloudCertDetail.companyName}}</p>
          <p><span>申请时间：</span>{{cloudCertDetail.createdDate}}</p>
          <p><span>业务状态：</span><i :class="'statusColor statusColor'+cloudCertDetail.status">{{cloudCertDetail.status|orderStatus}}</i></p>
          <p><span>审核意见：</span>{{cloudCertDetail.operationComment}}</p>
        </div>
      </div>
      <div class="cloudCertIllustrate">
        <h2>产品信息</h2>
        <p><span>订单号：</span>{{cloudCertDetail.orderNo}}</p>
        <p><span>规格：</span>{{Number(cloudCertDetail.productSpecs)===0?'纯API版':'网页版'}}</p>
        <p class="limitsOfAuthority"><span>接口权限：</span><u v-for="item of cloudCertDetail.interfaceNames" :key="item">{{item}}</u></p>
      </div>
    </div>
  </div>
</template>

<script>
import { orderStatus } from '@/utils/typeMatch';
export default {
  data () {
    return {
      detailsDisplay: false,
      detailsLoad: false,
      cloudCertDetail: {}
    }
  },
  filters: {
    orderStatus
  },
  methods: {
    getCloudDetails () {
      this.detailsDisplay = true
      this.detailsLoad = true
      this.$api.getCloudDetail({ orderNo: this.orderNo }).then(res => {
        this.detailsLoad = false
        this.cloudCertDetail = res.data
      }).catch(() => {
        this.detailsLoad = false
      })
    },
    closeDetails () {
      this.detailsDisplay = false
    },
    goPayment (orderNo, specs) {
      this.$store.commit('user/setOrderNo', orderNo)
      this.$router.push({ name: 'cloudCertificate', query: { type: 1, specs: specs } })
    },
    cancelCloudCert () {
      this.$confirm(`确定取消申请？`, '提示', {
        confirmButtonText: '确定',
        showCancelButton: '取消',
        type: 'warning'
      }).then(() => {
        this.cancelOrder()
      }).catch(() => { })
    },
    cancelOrder () {
      this.detailsLoad = true
      this.$api.cancelOrder({ orderNo: this.orderNo }).then(res => {
        this.detailsLoad = false
        if (res.code === '0') {
          this.$message.success(res.msg)
          this.getCloudDetails()
          this.$parent.refreshCloud()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.detailsLoad = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cloudCertDetails {
  position: fixed;
  top: 59px;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 9;
  background-color: #f5f8fb;
}
.cloudCertContent {
  width: 1300px;
  min-height: 100%;
  margin: 24px auto 0;
  padding: 0 32px 24px;
  background-color: #fff;
  position: relative;
}
.cloudCertClose {
  padding: 32px 0 24px;
  text-align: right;
}
.cloudCertIllustrate {
  padding: 24px 16px 16px;
  background: #f1f4fc;
  border-radius: 4px;
  margin-bottom: 24px;
  h2 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
    color: #303b50;
    line-height: 24px;
    margin-bottom: 20px;
    &:last-child {
      margin: 0;
    }
  }
  .limitsOfAuthority {
    margin-bottom: 12px;
  }
  span {
    color: #6c737b;
  }
  u {
    display: inline-block;
    height: 24px;
    padding: 0 8px;
    background: #dbe4ff;
    border-radius: 1px;
    border: 1px solid #a9beff;
    font-size: 12px;
    color: #1c67f3;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  i {
    font-size: 14px;
    color: #1c67f3;
    line-height: 19px;
  }
  .statusColor {
    height: 24px;
    line-height: 22px;
    display: inline-block;
    padding: 0 8px;
    border-radius: 15px;
    &.statusColor1,
    &.statusColor2 {
      color: #e89e1c;
      background: #fef8ec;
      border: 1px solid #fbbc4d;
    }
    &.statusColor3,
    &.statusColor4 {
      color: #52c41a;
      background: #eff9eb;
      border: 1px solid #d4f0c8;
    }
    &.statusColor5 {
      color: #e34d59;
      background: #ffeeee;
      border: 1px solid #f7caca;
    }
    &.statusColor6 {
      color: #707276;
      background: #ffffff;
      border: 1px solid #dbdce0;
    }
  }
}
.applyInfo {
  p {
    float: left;
    &:nth-child(2n-1) {
      width: 34%;
    }
    &:nth-child(2n) {
      width: 66%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:last-child {
      width: 100%;
    }
  }
}
</style>